"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _default = exports.default = {
  //生成kmz文件
  productFile: function productFile(id) {
    return (0, _request.default)({
      url: "/api/flighttask/productFile/".concat(id),
      method: 'get'
    });
  },
  //分页查询列表数据
  queryList: function queryList(page, limit, data) {
    return (0, _request.default)({
      url: "/api/flighttask/queryFlightTaskPageList/".concat(page, "/").concat(limit),
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: data
    });
  },
  //新增数据
  save: function save(form) {
    return (0, _request.default)({
      url: "/api/flighttask/addFlightTask",
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: form
    });
  },
  //更新数据
  updateById: function updateById(form) {
    return (0, _request.default)({
      url: "/api/flighttask/updateFlightTaskById",
      method: 'put',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: form
    });
  },
  //查询数据
  queryById: function queryById(id) {
    return (0, _request.default)({
      url: "/api/flighttask/queryFlightTaskById/".concat(id),
      method: 'get'
    });
  },
  //删除数据
  deleteById: function deleteById(id) {
    return (0, _request.default)({
      url: "/api/flighttask/deleteFlightTaskById/".concat(id),
      method: 'delete'
    });
  },
  //查询无人机列表
  queryUavList: function queryUavList() {
    return (0, _request.default)({
      url: "/api/uav/queryUavList",
      method: 'post'
    });
  },
  //查询策略列表
  queryFlightStrategyList: function queryFlightStrategyList(isAirport) {
    return (0, _request.default)({
      url: "/api/flightstrategy/queryFlightStrategyList/".concat(isAirport),
      method: 'get'
    });
  },
  //根据策略id查询航点列表
  queryRouteAndWaypointList: function queryRouteAndWaypointList(strategyId) {
    return (0, _request.default)({
      url: "/api/route/queryRouteAndWaypointList/".concat(strategyId),
      method: 'get'
    });
  },
  //手动关闭任务
  updateCloseById: function updateCloseById(id) {
    return (0, _request.default)({
      url: "/api/flighttask/updateCloseFlightTaskById/".concat(id),
      method: 'get'
    });
  },
  // 根据无人机查询任务列表
  queryFlightTaskListByUavId: function queryFlightTaskListByUavId(data) {
    return (0, _request.default)({
      url: "/api/flighttask/queryFlightTaskListByUavId",
      method: 'post',
      data: data
    });
  },
  // 根据id更新自动任务是否启用
  updateFlightTaskIsEnableById: function updateFlightTaskIsEnableById(data) {
    return (0, _request.default)({
      url: "/api/flighttask/updateFlightTaskIsEnableById",
      method: 'post',
      data: data
    });
  }
};