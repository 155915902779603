"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "control"
  }, [_c("div", {
    staticClass: "amap-wrapper",
    staticStyle: {
      height: "100vh"
    }
  }, [_c("el-amap", {
    ref: "map",
    attrs: {
      vid: "amapDemo",
      center: _vm.center,
      "expand-zoom-range": "true",
      zoom: _vm.zoom,
      plugin: _vm.plugins,
      pitch: 66,
      events: _vm.events
    }
  }, [_vm.showUavFlag && _vm.onLine == 1 ? _c("el-amap-marker", {
    attrs: {
      position: _vm.uavPoint.position,
      icon: _vm.uavIconImg
    }
  }) : _vm._e(), _vm._l(_vm.taskPointList, function (item, k) {
    return _c("el-amap-marker", {
      key: k,
      attrs: {
        offset: _vm.offset,
        position: item.position
      }
    }, [_c("i", {
      staticClass: "markItem"
    }, [_vm._v(_vm._s(k + 1))])]);
  }), _c("el-amap-polyline", {
    attrs: {
      path: _vm.taskLinePath,
      "stroke-style": "solid",
      "stroke-color": "yellow"
    }
  }), _c("el-amap-polyline", {
    attrs: {
      path: _vm.polylinePath,
      "stroke-style": "dashed",
      "stroke-color": "red"
    }
  }), _vm.airportFlag ? _c("el-amap-marker", {
    attrs: {
      position: _vm.airportPosition,
      icon: _vm.airportIconImg
    }
  }) : _vm._e()], 2)], 1), _c("div", {
    staticClass: "top",
    style: _vm.topStyle
  }, [_c("div", {
    staticClass: "top-box"
  }, [_c("img", {
    staticStyle: {
      height: "4.79rem"
    },
    attrs: {
      src: require("../../assets/images/control/top.png")
    }
  }), _c("div", {
    staticClass: "text-1 name"
  }, [_vm._v(_vm._s(_vm.uav.name))])])]), !_vm.airportFlag ? _c("div", {
    staticClass: "left-hidden-bt transition-time",
    style: _vm.moveLeftStyle,
    on: {
      click: _vm.hideLeftBoxClick
    }
  }, [_c("span", {
    staticClass: "el-icon-d-arrow-left transition-time",
    class: {
      "hideleft-icon-active": _vm.hideLeftFlag
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "control-box left-box",
    style: _vm.controlBoxStyle
  }, [_c("div", {
    staticClass: "left-box-topBox"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.leftTopBtnValue == 1,
      expression: "leftTopBtnValue == 1"
    }],
    staticStyle: {
      width: "24.23rem",
      height: "3.21rem"
    },
    attrs: {
      src: _vm.leftTopBtImg
    }
  }), _vm.airportFlag ? _c("div", {
    staticClass: "title",
    staticStyle: {
      width: "21.428rem"
    }
  }, [_vm._v("无人机数据")]) : _vm._e()]), _c("div", {
    staticClass: "content-box transition-time",
    class: {
      "hideleft-box-active": _vm.hideLeftFlag
    }
  }, [_c("div", [_c("div", {
    staticClass: "canshu-box feijicanshu",
    staticStyle: {
      height: "13.57rem"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.databg1
    }
  }), _c("div", {
    staticClass: "canshu-right",
    staticStyle: {
      "padding-top": "3.21rem"
    }
  }, [_c("div", {
    staticClass: "feijicanshu-right-item"
  }, [_c("img", {
    staticStyle: {
      width: "2.86rem",
      height: "2.86rem"
    },
    attrs: {
      src: require("../../assets/images/control/uav-icon.png")
    }
  }), _c("div", {
    staticClass: "data-box data-box-div2"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      margin: "0px"
    }
  }, [_vm._v("在线状态")]), _c("div", {
    staticClass: "data-box-value"
  }, [_c("div", {
    staticClass: "data-box-value-left"
  }, [_vm._v(_vm._s(_vm.onLine == 1 ? "在线" : "离线"))])])])]), _c("div", {
    staticClass: "feijicanshu-right-item"
  }, [_c("img", {
    staticStyle: {
      width: "2.86rem",
      height: "2.86rem"
    },
    attrs: {
      src: require("../../assets/images/control/height-icon.png")
    }
  }), _c("div", {
    staticClass: "data-box data-box-div2"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      margin: "0px"
    }
  }, [_vm._v("无人机状态")]), _c("div", {
    staticClass: "data-box-value"
  }, [_c("div", {
    staticClass: "data-box-value-left"
  }, [_vm._v(_vm._s(_vm.onLine == 1 ? _vm.uavOSD.modeCodeStr : "离线"))])])])]), _c("div", {
    staticClass: "feijicanshu-right-item",
    staticStyle: {
      width: "95%",
      "margin-top": "1rem"
    }
  }, [_c("img", {
    staticStyle: {
      width: "2.86rem",
      height: "2.86rem"
    },
    attrs: {
      src: require("../../assets/images/control/lnglat.png")
    }
  }), _c("div", {
    staticClass: "feijicanshu-data-box"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "0.5rem"
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("纬度")]), _c("div", {
    staticClass: "data-box-value"
  }, [_vm._v(_vm._s(_vm.uavOSD.latitude))])]), _c("div", [_c("div", {
    staticClass: "title"
  }, [_vm._v("经度")]), _c("div", {
    staticClass: "data-box-value"
  }, [_vm._v(_vm._s(_vm.uavOSD.longitude))])])])])])]), _c("div", {
    staticClass: "canshu-box feixingcanshu"
  }, [_c("img", {
    attrs: {
      src: _vm.databg2
    }
  }), _c("div", {
    staticClass: "canshu-right"
  }, [_c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "data-box"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      margin: "0.29rem 0"
    }
  }, [_vm._v("海拔高度")]), _c("div", {
    staticClass: "data-box-value",
    staticStyle: {
      width: "7.5rem",
      height: "1.86rem"
    }
  }, [_c("div", {
    staticClass: "data-box-value-left"
  }, [_vm._v(_vm._s(_vm.uavOSD.height))]), _c("div", {
    staticClass: "data-box-value-right"
  }, [_vm._v("m")])])])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "data-box"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      margin: "0.29rem 0"
    }
  }, [_vm._v("相对高度")]), _c("div", {
    staticClass: "data-box-value",
    staticStyle: {
      width: "7.5rem",
      height: "1.86rem"
    }
  }, [_c("div", {
    staticClass: "data-box-value-left"
  }, [_vm._v(_vm._s(_vm.uavOSD.elevation))]), _c("div", {
    staticClass: "data-box-value-right"
  }, [_vm._v("m")])])])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "dial-box"
  }, [_c("img", {
    staticClass: "dial",
    attrs: {
      src: require("../../assets/images/control/speed.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "point speed-point",
    style: {
      transform: "rotate(" + _vm.horizontalSpeedPoint + "deg)"
    },
    attrs: {
      src: require("../../assets/images/control/point.png")
    }
  })]), _c("div", {
    staticClass: "dial-title"
  }, [_vm._v("水平速度")])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "dial-box"
  }, [_c("img", {
    staticClass: "dial",
    attrs: {
      src: require("../../assets/images/control/speed.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "point speed-point",
    style: {
      transform: "rotate(" + _vm.verticalSpeedPoint + "deg)"
    },
    attrs: {
      src: require("../../assets/images/control/point.png")
    }
  })]), _c("div", {
    staticClass: "dial-title"
  }, [_vm._v("垂直速度")])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "dial-box"
  }, [_c("img", {
    staticClass: "dial pitch-dial",
    attrs: {
      src: require("../../assets/images/control/deg.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "point deg-point",
    style: {
      transform: "rotate(" + _vm.uavPitchPoint + "deg)",
      left: "calc(50% - 0.214rem)",
      top: "15%"
    },
    attrs: {
      src: require("../../assets/images/control/point.png")
    }
  })]), _c("div", {
    staticClass: "dial-title"
  }, [_vm._v("无人机俯仰角")])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "dial-box"
  }, [_c("img", {
    staticClass: "dial",
    attrs: {
      src: require("../../assets/images/control/deg.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "point deg-point",
    style: {
      transform: "rotate(" + _vm.uavYawPoint + "deg)",
      left: "calc(50% - 0.214rem)",
      top: "15%"
    },
    attrs: {
      src: require("../../assets/images/control/point.png")
    }
  })]), _c("div", {
    staticClass: "dial-title"
  }, [_vm._v("无人机偏航角")])])])]), _c("div", {
    staticClass: "canshu-box xinhaocanshu"
  }, [_c("img", {
    attrs: {
      src: _vm.databg3
    }
  }), _c("div", {
    staticClass: "canshu-right"
  }, [_c("div", {
    staticClass: "canshu-right-item"
  }, [_vm.uavOSD.positionState.quality > 0 ? _c("div", {
    staticClass: "gpsSignalLevel-box"
  }, [_c("div", {
    staticClass: "gpsSignalLevel",
    staticStyle: {
      height: "0.357rem"
    }
  }), _c("div", {
    staticClass: "gpsSignalLevel",
    staticStyle: {
      height: "0.714rem"
    },
    style: {
      background: _vm.uavOSD.positionState.quality > 1 ? "#54CDD2" : "gray"
    }
  }), _c("div", {
    staticClass: "gpsSignalLevel",
    staticStyle: {
      height: "1.071rem"
    },
    style: {
      background: _vm.uavOSD.positionState.quality > 2 ? "#54CDD2" : "gray"
    }
  }), _c("div", {
    staticClass: "gpsSignalLevel",
    staticStyle: {
      height: "1.429rem"
    },
    style: {
      background: _vm.uavOSD.positionState.quality > 3 ? "#54CDD2" : "gray"
    }
  }), _c("div", {
    staticClass: "gpsSignalLevel",
    staticStyle: {
      height: "1.786rem"
    },
    style: {
      background: _vm.uavOSD.positionState.quality > 4 ? "#54CDD2" : "gray"
    }
  })]) : _vm._e(), _vm.uavOSD.positionState.quality == 0 || _vm.uavOSD.positionState.quality == undefined ? _c("div", {
    staticClass: "xinhao-value",
    staticStyle: {
      "font-size": "1.143rem"
    }
  }, [_vm._v(" 无信号 ")]) : _vm._e(), _c("span", {
    staticClass: "xinhao-title"
  }, [_vm._v("GPS信号")])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "xinhao-value"
  }, [_c("span", [_vm._v(_vm._s(_vm.uavOSD.positionState.gpsNumber ? _vm.uavOSD.positionState.gpsNumber : "0"))]), _c("span", [_vm._v("个")])]), _c("span", {
    staticClass: "xinhao-title"
  }, [_vm._v("卫星数量")])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "xinhao-value"
  }, [_c("span", [_vm._v(_vm._s(_vm.uavOSD.battery.capacityPercent))]), _c("span", [_vm._v("%")])]), _c("span", {
    staticClass: "xinhao-title"
  }, [_vm._v("电池电量")])]), _c("div", {
    staticClass: "canshu-right-item"
  }, [_c("div", {
    staticClass: "xinhao-value"
  }, [_c("span", [_vm._v(_vm._s(_vm.uavOSD.battery.returnHomePower))]), _c("span", [_vm._v("%")])]), _c("span", {
    staticClass: "xinhao-title"
  }, [_vm._v("返航所需电量")])])])])])])]), _c("div", {
    staticClass: "control-box right-box",
    staticStyle: {
      width: "21.428rem"
    },
    style: {
      height: _vm.airportFlag ? "100vh" : "auto"
    }
  }, [!_vm.airportFlag ? _c("div", {
    staticClass: "right-box-topBox"
  }, [_c("img", {
    staticStyle: {
      width: "24.286rem",
      height: "3.5rem"
    },
    attrs: {
      src: _vm.rightTopBtImgNo
    }
  })]) : _vm._e(), _vm.airportFlag ? _c("div", {
    staticClass: "right-box-topBox"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightTopBtnValue == 1,
      expression: "rightTopBtnValue == 1"
    }],
    staticStyle: {
      width: "24.286rem",
      height: "3.5rem"
    },
    attrs: {
      src: _vm.rightTopBtImg1
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightTopBtnValue == 2,
      expression: "rightTopBtnValue == 2"
    }],
    staticStyle: {
      width: "24.286rem",
      height: "3.5rem"
    },
    attrs: {
      src: _vm.rightTopBtImg2
    }
  }), _c("div", {
    staticClass: "left-box-topBox-item",
    staticStyle: {
      "margin-right": "2.143rem"
    },
    style: {
      color: _vm.rightTopBtnValue == 2 ? "#fff" : "#333"
    },
    on: {
      click: function click($event) {
        return _vm.rightTopBtn(1);
      }
    }
  }, [_vm._v(" 机场状态 ")]), _c("div", {
    staticClass: "left-box-topBox-item",
    style: {
      color: _vm.rightTopBtnValue == 1 ? "#fff" : "#333"
    },
    on: {
      click: function click($event) {
        return _vm.rightTopBtn(2);
      }
    }
  }, [_vm._v(" 机场任务信息 ")])]) : _vm._e(), _vm.airportFlag ? _c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightTopBtnValue == 1,
      expression: "rightTopBtnValue == 1"
    }]
  }, [_c("div", {
    staticClass: "airport-data-box"
  }, [_c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("机场名称：")]), _c("div", [_vm._v(_vm._s(_vm.airportName))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("在线状态：")]), _c("div", {
    style: {
      color: _vm.airportOnline == 1 ? "#84DB42" : "red"
    }
  }, [_vm._v(_vm._s(_vm.airportOnline == 1 ? "在线" : "离线"))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("机场状态：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.modeCodeStr))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("紧急按钮：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.emergencyStopStateStr))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("舱门状态：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.coverStateStr))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("推杆状态：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.putterStateStr))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("补光灯状态：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.supplementLightStateStr))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("无人机电量：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.droneChargeState.capacityPercent ? _vm.airportOSD.droneChargeState.capacityPercent : 0) + "%")])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("无人机充电状态：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.droneChargeState.state ? _vm.airportOSD.droneChargeState.state == 1 ? "充电中" : "未充电" : "未充电"))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("无人机是否在舱：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.droneInDockStr))])]), _c("div", {
    staticClass: "airport-data-item"
  }, [_c("div", [_vm._v("机场声光报警：")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.alarmStateStr))])])]), _c("div", {
    staticClass: "airport-control-box"
  }, [_c("div", {
    staticClass: "airport-control-title"
  }, [_vm._v("机场操控")]), _c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("调试")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.modeCode == 2
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("debug", 2);
      }
    }
  }, [_vm._v("打开")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.modeCode != 2
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("debug", 0);
      }
    }
  }, [_vm._v("关闭")])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.airportOSD.modeCode == 2,
      expression: "airportOSD.modeCode == 2"
    }]
  }, [_c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("舱门")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.coverState == 1
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("cover", 1);
      }
    }
  }, [_vm._v("打开")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.coverState == 0
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("cover", 0);
      }
    }
  }, [_vm._v("关闭")])])]), _c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("推杆")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.putterState == 0
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("putter", 0);
      }
    }
  }, [_vm._v("夹紧")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.putterState == 1
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("putter", 1);
      }
    }
  }, [_vm._v("打开")])])]), _c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("无人机")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.onLine == 1
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("uavOnline", 1);
      }
    }
  }, [_vm._v("开机")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.onLine == 0
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("uavOnline", 0);
      }
    }
  }, [_vm._v("关机")])])]), _c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("充电")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.droneChargeState.state == 1
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("charge", 1);
      }
    }
  }, [_vm._v("打开")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.droneChargeState.state == 0
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("charge", 0);
      }
    }
  }, [_vm._v("关闭")])])]), _c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("补光灯")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.supplementLightState == 1
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("supplementLight", 1);
      }
    }
  }, [_vm._v("打开")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.supplementLightState == 0
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("supplementLight", 0);
      }
    }
  }, [_vm._v("关闭")])])]), _c("div", {
    staticClass: "airport-control-item"
  }, [_c("div", {
    staticClass: "airport-control-tip"
  }, [_vm._v("声光报警")]), _c("div", {
    staticClass: "airport-control-bt-box"
  }, [_c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.alarmState == 1
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("alarm", 1);
      }
    }
  }, [_vm._v("打开")]), _c("div", {
    staticClass: "airport-control-bt",
    class: {
      "airport-control-bt-active": _vm.airportOSD.alarmState == 0
    },
    on: {
      click: function click($event) {
        return _vm.airportControlClick("alarm", 0);
      }
    }
  }, [_vm._v("关闭")])])])])])]), _vm.rightTopBtnValue == 2 ? _c("div", [_vm.taskFlag == 0 ? _c("div", {
    staticClass: "xuanzerenwuBox"
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择飞行任务"
    },
    model: {
      value: _vm.selectTaskValue,
      callback: function callback($$v) {
        _vm.selectTaskValue = $$v;
      },
      expression: "selectTaskValue"
    }
  }, _vm._l(_vm.taskList, function (item, k) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("div", {
    staticClass: "xuanzerenwuBox-btn",
    on: {
      click: _vm.playTaskClick
    }
  }, [_vm._v("执飞")])], 1) : _vm._e(), _vm.taskFlag == 1 ? _c("div", {
    staticClass: "renwuInfo"
  }, [_c("div", {
    staticClass: "renwuInfo-title"
  }, [_vm._v("正在执行飞行任务")]), _vm.task ? _c("div", {
    staticClass: "renwuInfo-content"
  }, [_c("div", {
    staticClass: "renwuInfo-content-item"
  }, [_vm._v("任务名称："), _c("span", [_vm._v(_vm._s(_vm.task.name))])]), _c("div", {
    staticClass: "renwuInfo-content-item"
  }, [_vm._v("开始时间："), _c("span", [_vm._v(_vm._s(_vm.task.createTime))])])]) : _c("div", {
    staticClass: "empty-task"
  }, [_vm._v(" 暂无执飞任务! ")])]) : _vm._e()]) : _vm._e()]) : _vm._e()]), _vm.liveFlag ? _c("div", {
    staticClass: "video-box uav-video-box transition-time",
    staticStyle: {
      left: "26.43rem"
    },
    style: {
      transform: _vm.hideLeftFlag ? "translateX(-25rem)" : "translateX(0)"
    }
  }, [_c("img", {
    staticClass: "video-bg",
    attrs: {
      src: _vm.videoBg
    }
  }), _c("img", {
    staticClass: "video-tip",
    attrs: {
      src: _vm.videoTitle
    }
  }), _c("div", {
    staticClass: "video-title"
  }, [_vm._v("无人机")]), _vm.liveUrl != "" && _vm.liveUrl != undefined ? _c("video", {
    attrs: {
      id: "video",
      controls: ""
    }
  }) : _c("div", {
    staticClass: "empty-video-box"
  })]) : _vm._e(), _vm.airportUavFPVLiveFlag ? _c("div", {
    staticClass: "video-box airport-uavfpv-video-box"
  }, [_c("img", {
    staticClass: "video-bg",
    attrs: {
      src: _vm.videoBg
    }
  }), _c("img", {
    staticClass: "video-tip",
    attrs: {
      src: _vm.videoTitle
    }
  }), _c("div", {
    staticClass: "video-title"
  }, [_vm._v("FPV")]), _vm.airportUavFPVLiveUrl != "" && _vm.airportUavFPVLiveUrl != undefined ? _c("video", {
    attrs: {
      id: "airportUavFPVVideo",
      controls: ""
    }
  }) : _c("div", {
    staticClass: "empty-video-box"
  })]) : _vm._e(), _vm.airportLiveFlag ? _c("div", {
    staticClass: "video-box airport-video-box"
  }, [_c("img", {
    staticClass: "video-bg",
    attrs: {
      src: _vm.videoBg
    }
  }), _c("img", {
    staticClass: "video-tip",
    attrs: {
      src: _vm.videoTitle
    }
  }), _c("div", {
    staticClass: "video-title"
  }, [_vm._v("机场")]), _vm.airportLiveUrl != "" && _vm.airportLiveUrl != undefined ? _c("video", {
    attrs: {
      id: "airportVideo",
      controls: ""
    }
  }) : _c("div", {
    staticClass: "empty-video-box"
  })]) : _vm._e(), _vm.airportFlag ? _c("div", {
    staticClass: "airport-top-box"
  }, [_c("div", {
    staticClass: "airport-top-item"
  }, [_c("div", [_vm._v("舱内空调")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.airModeStr))])]), _c("div", {
    staticClass: "airport-top-item"
  }, [_c("div", [_vm._v("舱内温度")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.temperatureStr) + "℃")])]), _c("div", {
    staticClass: "airport-top-item"
  }, [_c("div", [_vm._v("舱内湿度")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.humidityStr) + "%")])]), _c("div", {
    staticClass: "airport-top-item",
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("div", [_vm._v("周围温度")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.environmentTemperatureStr) + "℃ ")])]), _c("div", {
    staticClass: "airport-top-item",
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("div", [_vm._v("周围风速")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.windSpeedStr) + "m/s")])]), _c("div", {
    staticClass: "airport-top-item",
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("div", [_vm._v("周围天气")]), _c("div", [_vm._v(_vm._s(_vm.airportOSD.rainfallStr))])])]) : _vm._e(), _c("div", {
    staticClass: "bt-box transition-time",
    style: _vm.moveLeftStyle
  }, [_vm.airportFlag ? _c("div", {
    staticClass: "bt",
    on: {
      click: _vm.backHomeClick
    }
  }, [_c("img", {
    attrs: {
      src: _vm.backhomeImg,
      alt: "返航"
    }
  }), _c("span", [_vm._v("返航")])]) : _vm._e(), _c("div", {
    staticClass: "bt",
    on: {
      click: _vm.liveClick
    }
  }, [_c("img", {
    attrs: {
      src: _vm.liveImg,
      alt: "直播"
    }
  }), _c("span", [_vm._v("直播")])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.liveCheckBoxVisible,
      expression: "liveCheckBoxVisible"
    }],
    staticClass: "live-choose-box"
  }, _vm._l(_vm.liveChooseList, function (item, index) {
    return _c("div", {
      staticClass: "live-choose-item",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.liveChooseClick(item);
        }
      }
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.check,
        expression: "!item.check"
      }],
      staticClass: "live-choose-round"
    }), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.check,
        expression: "item.check"
      }],
      staticClass: "el-icon-success",
      staticStyle: {
        color: "#54CDD2",
        "font-size": "1.071rem",
        "margin-right": "0.357rem"
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;