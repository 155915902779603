"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.json.stringify.js");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));
var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));
var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));
var _centerPoint = _interopRequireDefault(require("@/api/centerPoint/centerPoint"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// import Doc from '@/components/Doc'
// import SizeSelect from '@/components/SizeSelect'
var _default = exports.default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default,
    // SizeSelect,
    Search: _HeaderSearch.default
    // Doc
  },
  data: function data() {
    return {
      Avatar: _avatar.default,
      dialogVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '50px',
      form: {
        id: null,
        lng: 118.894953,
        lat: 31.912943
      },
      rules: {
        lng: [{
          required: true,
          message: '请输入活动经度',
          trigger: 'blur'
        }],
        lat: [{
          required: true,
          message: '请输入活动纬度',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'device', 'user', 'baseApi'])),
  created: function created() {
    this.queryCenter();
  },
  methods: {
    // 查询经纬度
    queryCenter: function queryCenter() {
      var _this = this;
      _centerPoint.default.queryCenterPoint().then(function (res) {
        if (res != null && res != "") {
          _this.form = res;
          _jsCookie.default.set('cp', JSON.stringify(res));
        }
      });
    },
    // 提交坐标
    commitCenter: function commitCenter(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _centerPoint.default.saveOrUp(_this2.form).then(function (_) {
            _this2.$message.success('修改成功！');
            _jsCookie.default.set('cp', JSON.stringify(_this2.form));
          });
        } else {
          _this2.$message.warning('请正确数据参数！');
          return false;
        }
      });
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open: function open() {
      var _this3 = this;
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.logout();
      });
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
      });
    }
  }
};