"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '/api/hangar';
var _default = exports.default = {
  // 分页查询机库列表数据
  queryListByPage: function queryListByPage(pageNum, pageSize, data) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/queryPageList/").concat(pageNum, "/").concat(pageSize),
      method: 'post',
      data: data
    });
  },
  // 新增
  addOrUpHangar: function addOrUpHangar(data) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/addOrUpHangar"),
      method: 'post',
      data: data
    });
  },
  // 删除
  deleteById: function deleteById(id) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/").concat(id),
      method: 'delete'
    });
  },
  // 查询所有机库
  queryHangarAll: function queryHangarAll() {
    return (0, _request.default)({
      url: "".concat(baseUrl),
      method: 'get'
    });
  },
  queryHangarById: function queryHangarById(hangarId) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/").concat(hangarId),
      method: 'get'
    });
  },
  // 根据id查询
  queryById: function queryById(hangarId) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/queryById/").concat(hangarId),
      method: 'get'
    });
  },
  // 机库控制
  hangarControl: function hangarControl(data) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/hangarControl"),
      method: 'post',
      data: data
    });
  },
  // 机库舱外视频
  queryAirportOutLiveUrl: function queryAirportOutLiveUrl(data) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/queryAirportOutLiveUrl"),
      method: 'post',
      data: data
    });
  },
  // 机库实时数据
  queryDockOSDBySN: function queryDockOSDBySN(data) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/queryDockOSDBySN"),
      method: 'post',
      data: data
    });
  },
  // 机库累计数据
  queryTotalData: function queryTotalData(data) {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/queryTotalData"),
      method: 'post',
      data: data
    });
  },
  // 查询所有机库以及在线状态
  queryHangarAllWithOnline: function queryHangarAllWithOnline() {
    return (0, _request.default)({
      url: "".concat(baseUrl, "/queryHangarAllWithOnline"),
      method: 'get'
    });
  }
};