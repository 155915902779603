"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _default = exports.default = {
  //查询所有无人机列表
  queryAllUav: function queryAllUav() {
    return (0, _request.default)({
      url: "/api/uav/queryAllUav",
      method: 'get'
    });
  },
  //分页查询无人机列表数据
  queryListByPage: function queryListByPage(data) {
    return (0, _request.default)({
      url: "/api/uav/queryUavListByPage",
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: data
    });
  },
  //新增无人机数据
  save: function save(form) {
    return (0, _request.default)({
      url: "/api/uav/add",
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: form
    });
  },
  //更新无人机数据
  updateById: function updateById(form) {
    return (0, _request.default)({
      url: "/api/uav/edit",
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: form
    });
  },
  //查询无人机数据
  queryById: function queryById(id) {
    return (0, _request.default)({
      url: "/api/uav/queryById/".concat(id),
      method: 'get'
    });
  },
  //查询无人机详细信息
  queryDetailById: function queryDetailById(id) {
    return (0, _request.default)({
      url: "/api/uav/queryDetailById/".concat(id),
      method: 'get'
    });
  },
  //删除无人机数据
  deleteById: function deleteById(id) {
    return (0, _request.default)({
      url: "/api/uav/deleteById/".concat(id),
      method: 'delete'
    });
  },
  // 查询飞机型号列表
  queryUavModelList: function queryUavModelList() {
    return (0, _request.default)({
      url: "/api/uav/uavModel/queryUavModelList",
      method: 'get'
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
    });
  },
  // 根据飞机型号查询能挂载的设备列表
  queryUavMountList: function queryUavMountList(data) {
    return (0, _request.default)({
      url: "/api/uav/uavMount/queryListByUavModelId",
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: data
    });
  },
  // 查询无人机实时数据
  queryUavOSDBySN: function queryUavOSDBySN(data) {
    return (0, _request.default)({
      url: "/api/uav/queryUavOSDBySN",
      method: 'post',
      data: data
    });
  },
  // 开始无人机直播
  startUavLive: function startUavLive(data) {
    return (0, _request.default)({
      url: "/api/uav/startUavLive",
      method: 'post',
      data: data
    });
  },
  /** 云盒相关接口 */
  // 查询云盒列表
  queryCloudBoxList: function queryCloudBoxList() {
    return (0, _request.default)({
      url: "/api/cloudbox/queryList",
      method: 'post'
    });
  },
  openPullLive: function openPullLive(cloudBoxSn) {
    return (0, _request.default)({
      url: "/api/cloudbox/openPullLive?cloudBoxSn=".concat(cloudBoxSn),
      method: 'get'
    });
  },
  // 获取直播地址
  queryLiveUrl: function queryLiveUrl(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/queryLiveUrl?cloudBoxSn=".concat(data.cloudBoxSn),
      method: 'get'
    });
  },
  // 连接tcp
  connectCloudBox: function connectCloudBox(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/connectCloudBox?cloudBoxSn=".concat(data.cloudBoxSn),
      method: 'get'
    });
  },
  // 关闭指定tpc
  closeCloudBoxTcp: function closeCloudBoxTcp(cloudBoxSn) {
    return (0, _request.default)({
      url: "/api/cloudbox/closeCloudBoxTcp?cloudBoxSn=".concat(cloudBoxSn),
      method: 'get'
    });
  },
  // 判断云盒是否在操控中
  checkCloudBoxControlState: function checkCloudBoxControlState(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/checkCloudBoxControlState?cloudBoxSn=".concat(data.cloudBoxSn),
      method: 'get'
    });
  },
  // 拍照
  cloudBoxPhotograph: function cloudBoxPhotograph(cloudBoxSn) {
    return (0, _request.default)({
      url: "/api/cloudbox/takePictures?cloudBoxSn=".concat(cloudBoxSn),
      method: 'get'
    });
  },
  // 暂停/恢复航线任务
  pauseTask: function pauseTask(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/pauseTask?cloudBoxSn=".concat(data.cloudBoxSn, "&type=").concat(data.type),
      method: 'get'
    });
  },
  // 开始/取消返航
  backHome: function backHome(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/backHome?cloudBoxSn=".concat(data.cloudBoxSn, "&type=").concat(data.type),
      method: 'get'
    });
  },
  // 开始/结束录像
  liveVideo: function liveVideo(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/liveVideo?cloudBoxSn=".concat(data.cloudBoxSn, "&type=").concat(data.type),
      method: 'get'
    });
  },
  // 开始指点飞行
  startPositionFly: function startPositionFly(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/startPositionFly",
      data: data,
      method: 'post'
    });
  },
  // 停止指点飞行
  stopPositionFly: function stopPositionFly(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/stopPositionFly",
      data: data,
      method: 'post'
    });
  },
  // 获取/释放无人机控制权
  gainAbilityControlUav: function gainAbilityControlUav(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/gainAbilityControlUav?cloudBoxSn=".concat(data.cloudBoxSn, "&type=").concat(data.type),
      method: 'get'
    });
  },
  // 操控无人机
  controlUav: function controlUav(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/controlUav",
      method: 'post',
      data: data
    });
  },
  // 操控云台
  controlPTZ: function controlPTZ(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/controlPTZ",
      method: 'post',
      data: data
    });
  },
  // 更改云台的相源
  changePTZCameraType: function changePTZCameraType(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/changePTZCameraType",
      method: 'post',
      data: data
    });
  },
  // 设置云台模式
  changePTZCameraMode: function changePTZCameraMode(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/changePTZCameraMode",
      method: 'post',
      data: data
    });
  },
  // 获取无人机下行遥测实时数据
  queryTelemetryDataBySN: function queryTelemetryDataBySN(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/queryTelemetryDataBySN",
      method: 'post',
      data: data
    });
  },
  // 获取无人机状态实时数据
  queryUavStateDataBySN: function queryUavStateDataBySN(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/queryUavStateDataBySN",
      method: 'post',
      data: data
    });
  },
  // 查询无人机的执飞状态
  queryFlightTaskMsgByUav: function queryFlightTaskMsgByUav(data) {
    return (0, _request.default)({
      url: "/api/uav/queryFlightTaskMsgByUav",
      method: 'post',
      data: data
    });
  },
  // 查询无人机的累计飞行数据
  queryTotalDataByUavId: function queryTotalDataByUavId(data) {
    return (0, _request.default)({
      url: "/api/uav/queryTotalDataByUavId",
      method: 'post',
      data: data
    });
  },
  // 判断无人机是否允许执飞
  checkUavFlightTask: function checkUavFlightTask(data) {
    return (0, _request.default)({
      url: "/api/uav/checkUavFlightTask",
      method: 'post',
      data: data
    });
  },
  // 云台测距
  rangeFindingPTZCamera: function rangeFindingPTZCamera(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/rangeFindingPTZCamera",
      method: 'post',
      data: data
    });
  },
  // 根据无人机查询是否绑定了机库
  checkAirportByUav: function checkAirportByUav(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/checkAirportByUav",
      method: 'post',
      data: data
    });
  },
  // 查询云盒的飞行历史
  queryCloudBoxVideoHistoryList: function queryCloudBoxVideoHistoryList(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/queryCloudBoxVideoHistoryList",
      method: 'post',
      data: data
    });
  },
  // 批量下载回放视频
  downloadVideoByList: function downloadVideoByList(data) {
    return (0, _request.default)({
      url: "/api/cloudbox/cloudboxdownloadrecord/downloadVideoByList",
      method: 'post',
      data: data
    });
  },
  // 查询无人机视频录播列表
  queryUavHistoryVideoList: function queryUavHistoryVideoList(data) {
    return (0, _request.default)({
      url: "/api/uav/queryUavHistoryVideoList",
      method: 'post',
      data: data
    });
  },
  // 查询所有无人机的累计飞行数据
  queryTotalData: function queryTotalData(data) {
    return (0, _request.default)({
      url: "/api/uav/queryTotalData",
      method: 'post',
      data: data
    });
  },
  queryUavListWithDept: function queryUavListWithDept(data) {
    return (0, _request.default)({
      url: "/api/uav/queryUavListWithDept",
      method: 'post',
      data: data
    });
  },
  queryUavOSDByIds: function queryUavOSDByIds(data) {
    return (0, _request.default)({
      url: "/api/uav/queryUavOSDByIds",
      method: 'post',
      data: data
    });
  },
  websocketTest: function websocketTest(data) {
    return (0, _request.default)({
      url: "/api/uav/websocketTest",
      method: 'post',
      data: data
    });
  }
};