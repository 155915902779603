"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRem = resetRem;
function resetRem() {
  // 基准大小
  var baseSize = 100;
  var basePc = baseSize / 1920; // 表示1920的设计图,使用100PX的默认值
  var vW = window.innerWidth; // 当前窗口的宽度
  console.log("vW", vW);
  var rem = vW * basePc / 100 * 14; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
  console.log("rem", rem);
  document.documentElement.style.fontSize = "".concat(rem, "px");
}