"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var base_path = '/api/flighttaskrecord';
var _default = exports.default = {
  //分页查询列表数据
  queryList: function queryList(page, limit, data) {
    return (0, _request.default)({
      url: "".concat(base_path, "/queryFlightTaskRecordPageList/").concat(page, "/").concat(limit),
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: data
    });
  },
  //更新立即执飞
  updateFlyById: function updateFlyById(data) {
    return (0, _request.default)({
      url: "".concat(base_path, "/updateFlyById"),
      method: 'post',
      data: data
    });
  },
  //查询飞行记录数据
  queryById: function queryById(id) {
    return (0, _request.default)({
      url: "".concat(base_path, "/queryFlightTaskRecordById/").concat(id),
      method: 'get'
    });
  },
  //查询飞行记录图片列表
  queryImgListById: function queryImgListById(id) {
    return (0, _request.default)({
      url: "".concat(base_path, "/queryFlightTaskRecordImgListById/").concat(id),
      method: 'get'
    });
  },
  queryVideoListById: function queryVideoListById(id) {
    return (0, _request.default)({
      url: "".concat(base_path, "/queryFlightTaskRecordVideoListById/").concat(id),
      method: 'get'
    });
  },
  //断点续飞
  updateContinuedFlyById: function updateContinuedFlyById(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/updateContinuedFlyById",
      method: 'post',
      data: data
    });
  },
  //第一视角推流
  pushPullLiveById: function pushPullLiveById(id) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/pushPullLiveById/".concat(id),
      method: 'get'
    });
  },
  //获取第一视角推流
  queryPullLiveById: function queryPullLiveById(id) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/queryPullLiveById/".concat(id),
      method: 'get'
    });
  },
  //订阅
  subscribe: function subscribe(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/subscribe",
      method: 'post',
      data: data
    });
  },
  //取消订阅
  cancelSubscribe: function cancelSubscribe(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/cancelSubscribe",
      method: 'post',
      data: data
    });
  },
  //航线规划
  routePlanning: function routePlanning(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/routePlanning",
      method: 'post',
      data: data
    });
  },
  //开始航线
  startRoute: function startRoute(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/startRoute",
      method: 'post',
      data: data
    });
  },
  //处理飞行记录
  updateFlyRecordById: function updateFlyRecordById(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/updateFlyRecordById",
      method: 'post',
      data: data
    });
  },
  //链接tcp
  connectTcp: function connectTcp(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/connectTcp",
      method: 'post',
      data: data
    });
  },
  //关闭tcp
  closeTcp: function closeTcp(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/closeTcp",
      method: 'post',
      data: data
    });
  },
  // 查询历史轨迹
  quryTrajectoryListById: function quryTrajectoryListById(id) {
    return (0, _request.default)({
      url: "/api/flighttrajectory/quryTrajectoryListById/".concat(id),
      method: 'get'
    });
  },
  //更新飞行时长 距离
  updateFlightDistanceDurationById: function updateFlightDistanceDurationById(data) {
    return (0, _request.default)({
      url: "/api/flighttaskrecord/updateFlightDistanceDurationById",
      method: 'post',
      data: data
    });
  }
};